<template>
  <v-app>
    <div>
      <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
        </v-alert>
    </div>
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto">
        <div class="mb-8 mt-8 text-center">
          <h1>Add Salary Component</h1>
        </div>
        <v-form ref="salarycomponent" v-model="valid">
          <v-row no-gutters>
            <v-col >
              <TextField v-model="formData.description" :label="'Name'" />
            </v-col>
            <v-col >
              <SelectField
                :items="frequency"
                v-model="formData.SalaryComponentFrequencyId"
                :label="'Frequency'"
                :itemText="'name'"
                :itemValue ="'id'"

              />
            </v-col>
            <v-col >
              <TextField v-model="formData.markup" :type="'number'"
               :label="'Markup'" />
            </v-col>
            <v-col dense>
              <v-radio-group v-model="formData.isTimeSheetDriven" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label="Is Timesheet ?">
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col>
              <v-radio-group v-model="formData.isAllowance" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label="is Allowance ?">
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col>
              <v-radio-group v-model="formData.isStatutory" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label="Is Statutory ?">
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col>
              <v-radio-group v-model="formData.isBasic" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label="Is Basic ?">
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col>
              <v-radio-group v-model="formData.isFlat" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label="Is Flat ?">
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col>
              <v-radio-group v-model="formData.isBaseOnBasic" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label="Is Based on Basic ?">
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col>
              <v-radio-group v-model="formData.isTaxable" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label="Is Taxable ?">
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="d-flex justify-end">

                      <Button
                      :label="'Submit'"
                      :btnType="'Submit'"
                      @onClick="dialog = true"
                      :disabled="!valid"
                      :isLoading="isSavingUser"
                      class="mr-4"
                    />

                    <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />

                  </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
     <ConfirmationDialog :title="'Are you sure you want to submit?'" :dialog="dialog" :btnTitle="'Yes'" :isLoading="isSavingUser" @close="dialog = false" @btnAction="onSave"/>
  </v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import { CREATE_SALARY } from '@/store/action-type'
export default {
  components: {
    TextField,
    SelectField,
    Button,
    ConfirmationDialog
  },
  data () {
    return {
      alertMessage: '',
      alert: false,
      alertType: '',
      valid: false,
      dialog: false,
      isSavingUser: false,
      formData: {
        description: '',
        SalaryComponentFrequencyId: '',
        isTimeSheetDriven: null,
        isAllowance: null,
        isStatutory: null,
        isBasic: null,
        isFlat: null,
        isBaseOnBasic: null,
        isTaxable: null,
        markup: 0
      },
      frequency: [{ id: 1, name: 'Hourly' }, { id: 2, name: 'Daily' }, { id: 3, name: 'weekly' }, { id: 4, name: 'Monthly' }, { id: 5, name: 'Yearly' }]
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    onSave () {
      this.isSavingUser = true
      console.log(this.formData)
      this.$store.dispatch(CREATE_SALARY, this.formData).then(() => {
        this.showAlertMessage('Salary component creation successful', 'success')
      }).catch((error) => {
        console.log(error)
        this.showAlertMessage('Salary component creation failed', 'error')
      }).finally(() => {
        this.isSavingUser = false
        this.dialog = false
        this.onReset()
      })
    },
    onReset () {
      this.$refs.salarycomponent.reset()
    }
  }
}
</script>
<style scoped>

.radiogroup{
  width: 50%;
  align-items: flex-start !important;
}
.row .col{
  box-shadow: none !important;
  padding: 0 !important;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
